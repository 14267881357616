@import 'mainVariables';

.ant-checkbox-wrapper {
  & + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-inner {
    border-color: $borderColor !important;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $brandColor;
    }
  }

  .ant-checkbox-input:focus {
    border-color: $brandColor;

    & + .ant-checkbox-inner {
      border-color: $brandColor;
    }
  }


  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $brandColor;
      border-color: $brandColor !important;
    }

    &:after {
      border-color: $brandColor;
    }
  }

}

.ant-select {
  .ant-select-selection {
    &:hover, &:active, &:focus {
      border-color: $borderColor;
      box-shadow: none;
    }
  }

  &.invalid .ant-select-selection {
    border-color: #FF0000;
  }

  .ant-select-selection__placeholder {
    color: #9b9b9b;
    font-size: 13px;
  }

  .ant-select-selection--single {
    height: 35px;

    .ant-select-selection__rendered {
      line-height: 33px;

      &:focus, &:hover, &:active {
        outline: none;
      }
    }
  }

  .ant-select-search input {
    outline: none !important;
    padding: 6px 0;
  }

  &.ant-select-focused {
    .ant-select-selection {
      border: 2px solid $borderColor !important;
      box-shadow: 0 0 0 0 white !important;
    }
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
  box-shadow: none;
  border: 2px solid $brandColor;
  outline: 0;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 2px solid $brandColor;
  outline: 0;
}

.ant-select-dropdown-menu-item {
  background-color: white;

  &:not(:last-child) {
    border-bottom: 1px solid #DDDDDD;
  }

  &:hover {
    background-color: rgba(179, 179, 179, 0.38) !important;
  }
}

.ant-select-dropdown-menu-item-active {
  background-color: rgba(255, 255, 255, 0.38) !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}

.ant-dropdown {
  .ant-dropdown-menu-item {
    //color: #018C44;

    .selected-language {
      img{
        margin: unset;
        border-radius: 50%;
      }
    }
  }
}

.ant-rate-star > div:hover, .ant-rate-star > div:focus {
  transform: none;
}


.ant-calendar-selected-day {
  .ant-calendar-date {
    border-color: $brandColor;
    color: $brandColor;
    background-color: white;

    &:hover {
      //background: #FF0000 !important;
    }
  }
}

.ant-calendar-date {
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background-color: #FF0000;
  }
}

.ant-calendar-today .ant-calendar-date {
  border-color: $borderColor;
  color: $textColor;
  background-color: white;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  background: #f5f5f5;
  border: 1px solid transparent;

  &:hover {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-calendar .ant-calendar-ok-btn:not(.ant-calendar-ok-btn-disabled) {
  background-color: $brandColor;
  border-color: $brandColor;

  &:hover {
    background-color: $brandColor;
    border-color: $brandColor;
  }
}

.ant-calendar .ant-calendar-time-picker-btn, .ant-calendar-today-btn {
  text-transform: capitalize;

  &:hover {
    color: $brandColor;
  }
}

.ant-calendar-picker {
  border-color: $borderColor;


  &:hover .ant-calendar-picker-input {
    border-color: $brandColor !important;
  }

  &.error .ant-calendar-picker-input {
    border: 1px solid #FF0000 !important;

    &:hover {
      border: 1px solid #FF0000 !important;
    }
  }

  .ant-calendar-picker-input:focus {
    box-shadow: none;
    border-color: $borderColor;
  }

  &:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    box-shadow: none;
    border-color: $borderColor;
  }

  .ant-calendar-picker-input {
    &:hover {
      border-color: $brandColor;
    }
  }

  .ant-calendar-picker-clear {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-top: -11px;
    right: 9px;
    width: 22px;
    height: 22px;
  }
}

.ant-popover-content {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 8px;
    border: 1px solid #DDDDDD;

    .ant-popover-inner-content {
      padding: 0;

    }
  }
}

.ant-notification-notice-icon {
  img {
    width: 25px;
    height: 25px;
  }
}

.custom-notification {
  cursor: pointer;
}

.notification-success {
  background-color: #2cb76c;

  .ant-notification-notice-message, .ant-notification-notice-description {
    color: white !important;
  }

  .ant-notification-notice-close i {
    color: white;
  }
}

.notification-error {
  background-color: #fb3838;

  .ant-notification-notice-message, .ant-notification-notice-description {
    color: white !important;
  }

  .ant-notification-notice-close i {
    color: white;
  }
}

.ant-tabs{
  flex-direction: row-reverse;
  padding-bottom: 60px;
}
.ant-tabs-nav{
  width: 48%!important;
  .ant-tabs-nav-wrap{
    width: 90%;
  }
  @media screen and (max-width: 1100px){
    width: 100%!important;
    .ant-tabs-nav-wrap{
      width: 96%;
    }
  }
}
.ant-tabs-ink-bar{
  display: none;
  width: 4px!important;
  background: #00BA85!important;
}
.ant-tabs-tab-active{
  border-color:$brandColor!important;
  background-color:$brandColor;
  .project{
    .project-title{
      color: white!important;
    }
    .project-description{
      color: white!important;
    }
    .options-wrapper{
      .options{
        color: white!important;
        svg{
          path{
            fill: white!important;
          }
        }
      }
    }
    .project-more-news{
      color: white!important;
      svg{
        path{
          fill: white!important;
        }
      }
    }
  }

  @media screen and (max-width: 1100px){
    border-color: #D0D5DD!important;
    background-color: unset;
    .project{
      .project-title{
        color: $textColor!important;
      }
      .project-description{
        color: $textColor!important;
      }
      .options-wrapper{
        .options{
          color: #475467!important;
          svg{
            path{
              fill:$brandColor!important;
            }
          }
        }
      }
      .project-more-news{
        color:$brandColor!important;
        svg{
          path{
            fill:$brandColor!important;
          }
        }
      }
    }
  }
}
.ant-tabs-tab{
  border: 1px solid #D0D5DD;
  text-align: left!important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
  white-space: unset!important;
}


.services-dropdown{
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 4px solid $brandColor!important;
  .services-info-block{
    width: 46%;
    padding: 32px;
    display: flex;
    background-color: rgba(189, 207, 254, 0.1);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg{
      display: flex;
      align-self: center;
    }
    .services-info{
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .info-title{
        font-size: 28px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.02em;
        text-align: left;
      }
      .info-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin: 20px 0;
        color: #667085;
      }
      .info-link{
        background-color:$brandColor;
        padding: 16px 60px;
        border-radius: 12px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .services-block{
    width: 54%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 28px 32px;
    background-color: white;
    .ant-dropdown-menu-item{
      background-color: unset!important;
    }
    .dropdown-items{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .item-title{
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0;
        text-align:left;
        margin: 12px 0;
        &:first-child{
          margin: 0 0 12px 0;
        }
      }
      .item-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align:left;
        color: #667085;
      }
      .item-more-info{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color:$brandColor;
        display: flex;
        align-items: center;
        margin-top: 12px;
        svg{
          margin-left: 12px;
        }
      }
    }
  }
}

.ant-tabs-nav{
  //height: 570px!important;
  overflow: scroll!important;
  &::-webkit-scrollbar{
    display: none!important;
  }
  @media screen and (max-width: 1100px){
    height: unset!important;
  }
}
.ant-tabs-nav-more{
  display: none!important;
}
.ant-pagination{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.ant-pagination-item-active{
  border-color:$textColor;
  a{
    color:$textColor;
  }
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab{
  cursor: auto!important;
}

.ant-modal{
  width: 800px!important;
  .ant-modal-content{
    width: 800px;
    .ant-modal-close{
      top: 12px;
    }
    .ant-modal-header{
      .ant-modal-title{
        .modal-title{
          display: flex;
          align-items: center;
          .modal-flag{
            width: 48px;
            height: 48px;
            border: 1px solid #EAECF0;
            border-radius: 10px;
            box-shadow: 0 1px 2px 0 #1018280D;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          span{
            font-size: 20px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.02em;
            text-align: left;
            margin-left: 16px;
            color: #101828;
          }
        }
      }
    }
    .ant-modal-footer{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: unset;
      .ant-btn-default{
        width: 49%;
        height: 48px;
        background-color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        color: #101828;
        border: 1px solid #101828;
        border-radius: 24px;
      }
      .ant-btn-primary{
        width: 49%;
        height: 48px;
        background-color: #101828;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        color: white;
        border-radius: 24px;
      }
    }
  }
  @media screen and (max-width: 1100px){
    width: 100%!important;
    .ant-modal-content{
      width: 100%!important;
      .ant-modal-footer{
        flex-direction: column-reverse;
        .ant-btn-default{
          width: 100%;
          margin-top: 20px;
        }
        .ant-btn-primary{
          width: 100%;
        }
      }
    }
  }
}

.ant-radio-checked .ant-radio-inner{
  border-color: #101828;
}
.ant-radio-inner::after{
  background-color: #101828;
}
.ant-radio-inner{
  border-color: #D0D5DD;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: #101828;
}

.ant-checkbox-inner{
  border-radius: 4px;
}