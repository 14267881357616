@import "../fonts";
@import "../mainVariables";

.footer-wrapper {
  -webkit-user-select: none !important;
  user-select: none !important;
  background-color: white;
  color: #667085;

  .top-part {
    display: flex;
    justify-content: space-between;
    padding: 60px $mainPadding;
    position: relative;

    .download-presentation{
      width: 300px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin-top: 20px;
      cursor: pointer;
      text-decoration: underline;
      position: absolute;
      bottom: 0;
    }
    .first-col {
      width: 50%;
      margin-top: 46px;
      .icons-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        a {
          margin-top: 14px;
          display: flex;

          svg {
            margin-right: 12px;
          }

          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #475467;
          }
        }
      }
    }

    .border{
      height: 1px;
      width: 254px;
      background-color: #F2F4F7;
      position: absolute;
    }
  }

  .bottom-part {
    border-top: 1px solid #EAECF0;
    margin: 30px $mainPadding;
    padding: 32px 0;
    display: flex;
    justify-content: space-between;

    .copyright {
      color: #667085;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .developed-by {
      display: flex;
      align-items: center;
      flex-direction: column;
      .develop{
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: right;
        display: flex;
        align-items: center;
        .footer-logo {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          margin-left: 10px;
          height: 40px;
        }
      }
      .design{
        color: #667085;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: right;
        display: flex;
        align-items: center;
        p{
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: right;
          color: #667085;
          text-decoration: underline;
          margin-left: 6px;
        }
      }
    }
  }

  .col {
    color: #667085;
    margin-top: 46px;
    margin-right: 20px;

    .active {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: $textColor;
    }

    .logo-wrapper {
      margin-bottom: 35px;
    }


    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #98A2B3;
      margin-bottom: 10px;
    }

    .service{
      color: #667085;
      font-size: 13px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;


      &:hover, p:hover {
        color: black;
      }
    }

    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #475467;


      &:hover, p:hover {
        color: black;
      }
    }


    p, a {
      color: #667085;
      font-size: 13px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text-wrapper {
    width: 350px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }


  @media screen and (max-width: 1100px) {
    .top-part {
      flex-direction: column;
      justify-content: flex-start;
      padding: 40px 26px;
      .border{
        display: none;
      }

      .col {
        margin-top: 20px;

        .text-wrapper {
          width: 100%;
        }
      }
    }

    .bottom-part {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .copyright {
        text-align: center;
      }
    }
  }

}