@import "../mainVariables";
@import "../mixins";

.contacts-page-wrapper {
  -webkit-user-select: none !important;
  user-select: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .contacts-block {
    padding: 0 0 80px $mainPadding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .contacts-main-block {
      width: 482px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .contacts-main-text {
        width: 100%;
        margin-bottom: 24px;

        .main-title {
          font-size: 36px;
          font-weight: 600;
          line-height: 48px;
          letter-spacing: -0.02em;
          text-align: left;
          color:$textColor;
        }

        .main-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #667085;
          margin-top: 20px;
        }
        .main-email{
          color:$brandColor;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left;
          margin-bottom: 20px;
        }
      }

      .contacts-inputs-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .fullName-inputs {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .custom-input-group {
            width: 48%;
            margin-bottom: unset;

            label {
              color: #475467;
            }

            input {
              height: 48px;
              border-bottom: unset;
              border: 1px solid #98A2B3;
              border-radius: 24px;
              padding: 14px 12px;
              margin-top: 6px;
              color: $textColor;

              &::placeholder {
                color: #98A2B3;
              }
            }
          }
        }

        .inputs {
          .error {
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 4px;

            svg {
              margin-right: 4px;
            }

            p {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0;
              text-align: left;
              color: #FE2F2F;
            }
          }

          .custom-input-group {
            width: 100%;
            margin-bottom: unset;
            margin-top: 16px;

            label {
              color: #475467;
            }

            input {
              height: 48px;
              border-bottom: unset;
              border: 1px solid #98A2B3;
              border-radius: 24px;
              padding: 14px 12px;
              margin-top: 6px;
              color: $textColor;

              &::placeholder {
                color: #98A2B3;
              }
            }

            textarea {
              height: 120px;
              border-bottom: unset;
              border: 1px solid #98A2B3;
              border-radius: 24px;
              padding: 10px 14px;
              margin-top: 6px;
              color: $textColor;

              &::placeholder {
                color: #98A2B3;
              }
            }
          }

          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
            color: #344054;
            margin-bottom: 6px;
          }
        }
      }

      .sent-email {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        background-color: $brandColor;
        color: white;
        border-radius: 24px;
        margin-top: 16px;
      }
    }

    .contact-map-block {
      width: 50%;
      height: 900px;

      .yandexMap {
        width: 100%;
        height: 100%;

        .ymaps-2-1-79-map {
          width: 100% !important;
          height: 100% !important;

        }
      }
    }
  }

  .information-block {
    width: 1120px;
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .contact-info {
      width: calc(96% / 3);
      height: 266px;
      background-color: #F2F4F7;
      padding: 24px;
      border-radius: 24px;

      .icon-wrapper {
        background-color: #FE2F2F1A;
        padding: 12px 32px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 74%;

        p {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          color: #FE2F2F;
          margin-left: 20px;
        }
      }

      .info-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 24px;
        color:$textColor;
      }

      .wrapper {
        height: 60px;
        margin-top: 12px;

        .information {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color:#475467;
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .information-block{
      width: 1020px;
    }
  }

  @media screen and (max-width: 1100px) {
    .contacts-block {
      flex-direction: column;
      padding: 60px $mainPadding 0;

      .contacts-main-block {
        width: 100%;

        .contacts-main-text {
          .main-title {
            font-size: 30px;
            line-height: 40px;
          }

          .main-description {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .contacts-inputs-wrapper {
          .fullName-inputs {
            flex-direction: column;

            .custom-input-group {
              width: 100%;
            }
          }
        }

        .contact-services {
          .services-block {
            flex-direction: column;
            align-items: start;

            .ant-checkbox-group {
              .ant-row {
                display: flex;
                flex-direction: column;

                .services-left-block {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .contact-map-block {
        display: none;
      }
    }
    .information-block {
      flex-direction: column;
      margin-bottom: unset;

      .contact-info {
        width: 100%;
        margin-top: 32px;
        .icon-wrapper{
          width: 84%;
        }
      }
    }
  }
}
