@import "../mainVariables";
@import "../mixins";

.services-page-wrapper {
  -webkit-user-select: none !important;
  user-select: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .services-main-block {
    width: 1120px;
    padding: 90px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .services-main-info {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .services-main-title {
        font-size: 52px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -0.02em;
        text-align: left;
        color: $textColor;
      }

      .services-main-description {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        margin-top: 24px;
      }

      .services-main-link {
        min-width: 160px;
        height: 48px;
        border-radius: 24px;
        background-color: $brandColor;
        color: white;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        padding: 12px 32px 12px 32px;
        margin-top: 24px;
        cursor: pointer;
      }
    }

    .services-main-img {
      width: 40%;

      .services-img-wrapper {
        position: relative;
        padding-top: 67.2%;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .services-block {
    width: 1120px;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .services-block-info {
      .services-block-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: center;
        color: $textColor;
      }

      .services-block-description {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        color: #475467;
        margin-top: 12px;
      }
    }

    .blocks-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 60px;

      .blocks {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 120px;

        .blocks-text {
          width: 48%;

          .blocks-title {
            font-size: 36px;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: -0.02em;
            text-align: left;
            color: $textColor;
          }

          .blocks-description {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #475467;
            margin-top: 24px;
          }
        }

        .blocks-img {
          width: 48%;
          background: linear-gradient(270deg, #F2F4F7 0%, rgba(233, 233, 233, 0) 93.23%);

          .img-wrapper {
            position: relative;
            padding-top: 74.6%;

            img {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }

        &:nth-child(even) {
          flex-direction: row-reverse;

          .blocks-img {
            background: linear-gradient(90deg, #F2F4F7 2.12%, rgba(233, 233, 233, 0) 85.97%);
          }
        }
      }
    }
  }

  .services-feature {
    width: 1120px;
    padding: 90px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .services-feature-title {
      font-size: 36px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.02em;
      text-align: center;
      color: $textColor;
    }

    .services-feature-description {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      color: #475467;
      margin-top: 12px;
    }

    .feature-wrapper {
      margin-top: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .features-block {
        width: calc(94% / 3);
        padding: 72px 52px;
        box-shadow: 2px 2px 12px 0 #EAEAEAB0;
        .features-block-icon{
          img{
            width: 84px;
            height: 84px;
          }
        }
        .features-block-hoverIcon{
          display: none;
          img{
            width: 84px;
            height: 84px;
          }
        }
        .features-block-title{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: -0.02em;
          text-align: left;
          color:$textColor;
          margin-top: 20px;
        }
        .features-block-description{
          height: 96px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #475467;
          margin-top: 20px;
          overflow: hidden;
        }

        &:hover{
          background-color:$brandColor;
          .features-block-icon{
            display: none;
          }
          .features-block-hoverIcon{
            display: flex;
          }
          .features-block-title{
            color: white;
          }
          .features-block-description{
            color: white;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .services-main-block{
      width: 1020px;
    }
    .services-block{
      width: 1020px;
    }
    .services-feature{
      width: 1020px;
    }
  }

  @media screen and (max-width: 1100px){
    .services-main-block{
      width: unset;
      flex-direction: column;
      padding: 40px $mainPadding;
      .services-main-info{
        width: 100%;
        .services-main-title{
          font-size: 36px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .services-main-description{
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: -0.02em;
          text-align: left;
          margin-top: 24px;
        }
        .services-main-link{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .services-main-img{
       display: none;
      }
    }
    .services-block{
      padding:unset;
      width: unset;
      .services-block-info{
        padding: 0 $mainPadding;
        .services-block-title{
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.02em;
          text-align: center;
        }
        .services-block-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: center;
        }
      }
      .blocks-wrapper{
        margin-top: unset;
        .blocks{
          flex-direction: column-reverse;
          margin-top: 40px;
          .blocks-text{
            width: 100%;
            padding: 12px $mainPadding 52px;
            .blocks-title{
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
              letter-spacing: -0.02em;
              text-align: left;
            }
            .blocks-description{
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.02em;
              text-align: left;
              margin-top: 16px;
            }
          }
          .blocks-img{
            width: 100%;
          }
          &:nth-child(even) {
            flex-direction: column-reverse;
          }
        }
      }
    }
    .services-feature{
      padding: 20px $mainPadding;
      width: unset;
      .services-feature-title{
        font-size: 26px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.02em;
        text-align: left;
      }
      .services-feature-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 12px;
      }
      .feature-wrapper{
        margin-top: 32px;
        flex-direction: column;
        .features-block{
          width: 100%;
          padding: 42px 36px;
          background-color: #F2F4F7;
          box-shadow: unset;
          border-bottom: 1px solid #D0D5DD;
          pointer-events: none;
          .features-block-icon{
            display: none;
            img{
              width: 52px;
              height: 52px;
            }
          }
          .features-block-hoverIcon{
            display: flex;
            img{
              width: 52px;
              height: 52px;
            }
          }
          .features-block-title{
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: -0.02em;
            text-align: left;
            margin-top: 16px;
          }
          .features-block-description{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            margin-top: 12px;
          }
          &:last-child{
            border-bottom: unset;
          }
        }
      }
    }
  }
}