@import "../mainVariables";
@import "../mixins";

/*todo .color .fixe գրի header-wrapper-ի մեջ*/

.backgroundColor{
  background-color:#F2F4F7 ;
}
.fixe{
  position: fixed!important;
  width: 100%;
  background-color: white;
}
.header-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  padding: 0 $mainPadding;
  position: relative;
  top: 0;
  z-index: 1002;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #EAECF0;
  .bg-block{
    width: 52%;
    position: absolute;
    right: 0;
    background-color: white;
    border-top-left-radius: 100%;
    overflow: hidden;
    height: 235px;
  }

  a{
    .sos-logo{
      svg{
        width: 150px;
        height: 100px;
      }
    }
  }

  .nav-menu {
    display: flex;
    align-items: center;
    z-index: 1000;
    margin-left: 130px;
    .active{
      .nav-item{
        color:$textColor;
      }
    }

    .nav-item {
      margin-left: 32px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      cursor: pointer;
      color: #475467;

      &:hover {
        color:$textColor;
      }

      .selected-language{
        background: #EFF2F4;
        padding: 12px;
        border-radius: 4px;
        .language-icon{
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            margin: unset;
          }
        }
      }
    }

    .header_burger {
      display: none;
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      right: 23px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
  div{
    position: relative !important;
  }

  @media all and (max-width: 1300px) {
    .nav-menu {

      .nav-item {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 1100px){
    border-bottom: 2px solid #EAECF0;
    .bg-block{
      display: none!important;
    }
    .header_burger{
      display: flex!important;
    }
    .nav-menu {

      .nav-item {
        display: none;
      }
    }
  }
}
