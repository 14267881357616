@import "../mainVariables";
@import "../mixins";

.about-wrapper{
  -webkit-user-select:none!important;
  user-select: none!important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .about-main-wrapper{
    width: 1120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 0 80px;
    .about-main{
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .about-main-title{
        font-size: 52px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: -0.02em;
        text-align: center;
        color:$textColor;
      }
      .about-main-description{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: center;
        margin-top: 24px;
        color: #475467;
      }
    }
  }
  .sponsors-wrapper{
    padding-bottom: unset;
  }

  .blocks-wrapper{
    width: 1120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blocks{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 120px;
      .blocks-img{
        width: 48%;
        .img-wrapper{
          position: relative;
          padding-top: 72.3%;
          img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
      }
      .blocks-text{
        width: 48%;
        display: flex;
        align-items: start;
        justify-content: start;
        .blocks-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left;
          color:#475467;
        }
      }
      &:nth-child(odd){
        flex-direction: row-reverse;
      }
    }
  }
  .contacts-wrapper{
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(242, 244, 247, 1) 65%);
  }

  @media screen and (max-width: 1250px){
    .about-main-wrapper{
      width: 1020px;
    }
    .blocks-wrapper{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1100px){
    .about-main-wrapper{
      width: 100%;
      padding: 45px $mainPadding;
      .about-main{
        width: 100%;
        .about-main-title{
          font-size: 34px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: -0.02em;
          text-align: center;
          overflow: hidden;
        }
        .about-main-description{
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: center;
          overflow: hidden;
        }
      }
    }
    .blocks-wrapper{
      width: 100%;
      padding: 40px $mainPadding 0;
      .blocks{
        flex-direction: column-reverse;
        margin-top: 64px;
        .blocks-img{
          width: 100%;
        }
        .blocks-text{
          width: 100%;
          margin-bottom: 40px;
          .blocks-description{
            width: 100%;
            font-size: 16px;
          }
        }
        &:nth-child(odd){
          flex-direction: column-reverse;
        }
      }
    }
  }
}