@import "../mainVariables";
@import "../mixins";

.partners-page-wrapper{
  -webkit-user-select: none !important;
  user-select: none !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .partners-page-info{
    width: 918px;
    padding: 120px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .partners-page-title{
      color: #101828;
      text-align: center;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
      letter-spacing: -1.04px;
    }
    .partners-page-description{
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0;
      text-align: center;
      color: #475467;
      margin-top: 24px;
    }
  }
  .partners{
    width: 1120px;
    padding: 140px 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .partners-title{
      display: flex;
      align-self: center;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #667085;
    }
    .partners-component{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      .partners-block{
        width: calc(100% / 3 - 20px);
        margin-top: 52px;
        .partners-img-wrapper{
          width: 100%;
          height: 222px;
          box-shadow: 2px 2px 14px 2px #F5F5F5;
          padding: 88px 48px;
          .img-block{
            position: relative;
            padding-top: 34.6%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .partners-block-title{
          width: 80%;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #475467;
          margin-top: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .consultation-wrapper{
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(242, 244, 247, 1) 65%);
  }

  @media screen and (max-width: 1250px){
    .partners{
      width: 1020px;
    }
  }
  @media screen and (max-width:1100px){
    .partners-page-info{
      width: 100%;
      padding: 46px $mainPadding 0;
      .partners-page-title{
        width: 100%;
        font-size: 34px;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: -0.02em;
        text-align: center;
      }
      .partners-page-description{
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-align: center;
        margin-top: 24px;
      }
    }
    .partners{
      width: 100%;
      padding: 76px $mainPadding 116px;
      .partners-component{
        flex-wrap: unset;
        flex-direction: column;
        .partners-block{
          width: 100%;
          .partners-img-wrapper{
            height: unset;
            .img-block{
              top: unset;
              left: unset;
              transform: unset;
            }
          }
        }
      }
    }
  }
}